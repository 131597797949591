import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Paper, Divider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../theme/theme';
import NestedModalDelete from '../../components/func/button/NestedModalDelete';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase';

export default function EngineerPreviousDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = location.state || {};
  const [project, setProject] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const user = auth.currentUser;
        // console.log('Current User:', user);
        if (user && projectId) {
          const projectDocRef = doc(firestore, `users/${user.uid}/profile/previous/${user.uid}/${projectId}`);
          // console.log('Project Document Reference:', projectDocRef);
          const projectDocSnap = await getDoc(projectDocRef);
          if (projectDocSnap.exists()) {
            // console.log('Project Document Data:', projectDocSnap.data());
            setProject(projectDocSnap.data());
          } else {
            console.log('No such document!');
          }
        } else {
          console.log('User or projectId not found.');
        }
      } catch (error) {
        console.error('Error fetching project data: ', error);
      }
    };

    fetchProject();
  }, [projectId]);

  if (!project) {
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            No Project Data
          </Typography>
          <Button variant="outlined" sx={{ marginTop: 2 }} onClick={() => navigate('/profile_previous')}>
            BACK
          </Button>
        </Container>
      </ThemeProvider>
    );
  }

  const handleEditClick = () => {
    navigate('/profile_previous_edit', { state: { projectId } });
  };

  const handleDeleteClick = () => {
    setModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const user = auth.currentUser;
      if (user && projectId) {
        const projectDocRef = doc(firestore, `users/${user.uid}/profile/previous/${user.uid}/${projectId}`);
        await deleteDoc(projectDocRef);
        console.log('Project deleted');
        setModalOpen(false);
        navigate('/profile_previous');
      }
    } catch (error) {
      console.error('Error deleting project: ', error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleBackClick = () => {
    navigate('/profile_previous');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
        <Paper sx={{ padding: 4 }}>
          <Typography variant="h4" component="h1" sx={{ marginBottom: 2 }} gutterBottom>
            Previous Project
          </Typography>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Name
            </Typography>
            <Typography variant="body1">{project.projectname}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Scale
            </Typography>
            <Typography variant="body1">{project.scale}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Position
            </Typography>
            <Typography variant="body1">{project.position_id}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Tools
            </Typography>
            <Typography variant="body1">{project.tools_id}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              About
            </Typography>
            <Typography variant="body1">{project.about}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Period
            </Typography>
            <Typography variant="body1">{`${project.period_start} -- ${project.period_end}`}</Typography>
          </Box>
          <Divider sx={{ marginBottom: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
            {/* <Button variant="contained" color="primary" onClick={handleEditClick}>
              EDIT
            </Button>
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: theme.palette.primaryhover.main, 
                color: 'white',
                '&:hover': {
                  backgroundColor: theme.palette.primaryhover.dark,
                }
              }} 
              onClick={handleDeleteClick}
            >
              DELETE
            </Button> */}
            <Button variant="outlined" onClick={handleBackClick}>
              BACK
            </Button>
          </Box>
        </Paper>
        <NestedModalDelete
          open={modalOpen}
          handleClose={handleModalClose}
          handleDelete={handleDeleteConfirm}
          title="Confirm Delete"
          description="Are you sure you want to delete this project?"
        />
      </Container>
    </ThemeProvider>
  );
}

import * as React from 'react';
import { Box } from '@mui/material';

const FormContainer = ({ children }) => (
  <Box
    component="div"
    sx={{
      mt: 3,
      p: 3,
      borderRadius: 2,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
      backgroundColor: 'white',
      width: '100%',
      // maxWidth: 1000,
      minHeight: 350,
      // maxHeight: 'auto', // Allow auto height to fit content
      mx: 2,
      // overflowY: 'auto', // Ensure overflow is handled
    }}
  >
    {children}
  </Box>
);

export default FormContainer;

import * as React from 'react';
import { Typography, Box } from '@mui/material';

export default function GenderDisplay({ gender = '' }) {
  let genderText = '';
  if (gender === 'male') genderText = 'Male';
  else if (gender === 'women') genderText = 'Women';
  else if (gender === 'other') genderText = 'Other';
  else genderText = 'Not Specified';

  // console.log('GenderDisplay:', gender);

  return (
    <Box sx={{ borderBottom: '1px solid #e0e0e0', mb: 1, pb: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Gender
      </Typography>
      <Typography variant="body2">
        {genderText}
      </Typography>
    </Box>
  );
}

import React from 'react';
import { Box, TextField } from '@mui/material';

const OtherSkills = ({ label, value, onChange }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label={label}
        value={value === "Null" || value === undefined ? "" : value}
        onChange={onChange}
        fullWidth
        variant="outlined"
        placeholder="Threejs, docker and so on"
      />
    </Box>
  );
};

export default OtherSkills;

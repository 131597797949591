import React from 'react';
import { Typography, Box } from '@mui/material';

const TextDisplayComponent = ({ label, value }) => {
  return (
    <Box sx={{ borderBottom: '1px solid #e0e0e0', mb: 1, pb: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
      <Typography variant="body2">
        {value}
      </Typography>
    </Box>
  );
};

export default TextDisplayComponent;

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Box, CssBaseline, Grid, Link, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TextDisplayComponent from '../../components/func/form/TextDisplayComponent';
import CountryDisplay from '../../components/func/form/CountryDisplay';
import GenderDisplay from '../../components/func/form/GenderDisplay';
import FormContainer from '../../components/func/form/FormContainer';
import FormContainerMove from '../../components/func/form/FormContainerMove';
import InfoTextDisplayNoIcon from '../../components/func/form/InfoTextDisplayNoIcon';
import theme from '../../theme/theme';
import { firestore } from '../../firebase';
import { doc, getDoc, collectionGroup, getDocs } from 'firebase/firestore';

export default function ProjectEngineer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { profileId } = location.state || {};
  const [profileData, setProfileData] = React.useState(null);
  const [skillData, setSkillData] = React.useState(null);
  const [projectData, setProjectData] = React.useState([]);
  const [profiles, setProfiles] = React.useState([]);
  const currentDate = new Date();

  const getStatus = (startPeriod, endPeriod) => {
    const start = new Date(startPeriod.replace(/\./g, '-'));
    const end = new Date(endPeriod.replace(/\./g, '-'));
    if (end < currentDate) {
      return 'previous';
    } else if (start > currentDate) {
      return 'future';
    } else {
      return 'now';
    }
  };

  const sortProjectsByStatus = (projects) => {
    return projects.sort((a, b) => {
      const statusOrder = { 'future': 0, 'now': 1, 'previous': 2 };
      return statusOrder[getStatus(a.startPeriod, a.endPeriod)] - statusOrder[getStatus(b.startPeriod, b.endPeriod)];
    });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (profileId) {
          const profileDocRef = doc(firestore, `users/${profileId}/profile`, profileId);
          const profileDocSnap = await getDoc(profileDocRef);

          if (profileDocSnap.exists()) {
            const profileData = profileDocSnap.data();
            setProfileData(profileData);
            setSkillData({
              language: profileData.programmingLanguages.join(', '),
              tools: profileData.tools.join(', '),
              technologies: profileData.technologies.join(', '),
              free: profileData.others,
            });

            const projectsCollection = collectionGroup(firestore, 'projects');
            const querySnapshot = await getDocs(projectsCollection);

            const userProjects = querySnapshot.docs
              .map(doc => ({ id: doc.id, ...doc.data() }))
              .filter(project =>
                project.pmEmails.includes(profileData.email) ||
                project.frontendEmails.includes(profileData.email) ||
                project.backendEmails.includes(profileData.email) ||
                project.designEmails.includes(profileData.email) ||
                project.testEmails.includes(profileData.email) ||
                project.otherEmails.includes(profileData.email)
              );

            const sortedProjects = sortProjectsByStatus(userProjects);
            setProjectData(sortedProjects);

            // Fetch profiles
            const profilesCollection = collectionGroup(firestore, 'profile');
            const profilesSnapshot = await getDocs(profilesCollection);
            const profilesData = profilesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProfiles(profilesData);
          } else {
            console.log('No such document!');
          }
        }
      } catch (error) {
        console.error('Error fetching profile data: ', error);
      }
    };

    fetchData();
  }, [profileId]);

  if (!profileData || !skillData) {
    return <div>Loading...</div>;
  }

  const handleInfoClick = (project, target) => {
    if (target === 'docusign') {
      navigate('/docusign', { state: { projectId: project.id, project } });
    } else {
      navigate('/engineer_project_detail', { state: { projectId: project.id, project } });
    }
  };

  const getEngineerNames = (emails) => {
    const uniqueNames = new Set();
    emails.forEach(email => {
      const profile = profiles.find(profile => profile.email === email);
      if (profile) {
        const nameParts = profile.name.split(' ');
        uniqueNames.add(nameParts[nameParts.length - 1]); // Add the last part of the name
      } else {
        uniqueNames.add(email);
      }
    });
    return Array.from(uniqueNames);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', paddingBottom: 4 }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: -2,
            paddingBottom: 10,
            paddingRight: 4,
            paddingLeft: 4,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            gap: 2,
            overflowX: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
            <FormContainer sx={{ flex: 1 }}>
              <Box sx={{ position: 'relative' }}>
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Profile Detail
                </Typography>
              </Box>
              <TextDisplayComponent label="Name (English)" value={profileData.name} />
              <TextDisplayComponent label="Email" value={profileData.email} />
              <CountryDisplay country={profileData.country_id} />
              <TextDisplayComponent label="Date of Birth" value={profileData.birthday} />
              <GenderDisplay gender={profileData.gender_id} />
            </FormContainer>

            <FormContainer sx={{ flex: 1 }}>
              <Box sx={{ position: 'relative' }}>
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Skill Detail
                </Typography>
              </Box>
              <TextDisplayComponent label="Programing Language" value={skillData.language} />
              <TextDisplayComponent label="Tools" value={skillData.tools} />
              <TextDisplayComponent label="Technologies" value={skillData.technologies} />
              <TextDisplayComponent label="Others" value={skillData.free} />
              <Grid item xs>
                <Link href="#" variant="body2" color="primary" onClick={() => navigate('/project_engineer_previous', { state: { profileId } })}>
                  Previous Projects
                </Link>
              </Grid>
            </FormContainer>
          </Box>

          <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
            <FormContainerMove sx={{ flex: 2 }}>
              <Box sx={{ position: 'relative' }}>
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Project Detail
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {projectData.map((project, index) => (
                  <InfoTextDisplayNoIcon
                    key={index}
                    label={getStatus(project.startPeriod, project.endPeriod)}
                    value={project.projectName}
                    period={`${project.startPeriod} -- ${project.endPeriod}`}
                    engineer={`${getEngineerNames(project.pmEmails).join(', ')}, 
                      ${getEngineerNames(project.frontendEmails).join(', ')}, 
                      ${getEngineerNames(project.backendEmails).join(', ')}, 
                      ${getEngineerNames(project.designEmails).join(', ')}, 
                      ${getEngineerNames(project.testEmails).join(', ')}, 
                      ${getEngineerNames(project.otherEmails).join(', ')}`}
                    iconColor={
                      getStatus(project.startPeriod, project.endPeriod) === 'future' ? '#e45e3e' :
                      getStatus(project.startPeriod, project.endPeriod) === 'now' ? '#289b8f' :
                      '#555'
                    }
                    onInfoClick={(target) => handleInfoClick(project, target)}
                  />
                ))}
              </Grid>
            </FormContainerMove>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mt: 2, mb: 10 }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            BACK
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Home as HomeIcon,
  Build as BuildIcon,
  Assignment as AssignmentIcon,
  Login as LoginIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
} from "@mui/icons-material";
import { Link, Routes, Route, useNavigate } from 'react-router-dom';

import Profile from '../../routes/profile/Profile';
import ProfileEdit from '../../routes/profile/ProfileEdit';
import ProfilePrevious from '../../routes/profile/ProfilePrevious';
import ProfilePreviousCreate from '../../routes/profile/ProfilePreviousCreate';
import ProfilePreviousDetail from '../../routes/profile/ProfilePreviousDetail';
import ProfilePreviousEdit from '../../routes/profile/ProfilePreviousEdit';
import ProfileSkillEdit from '../../routes/profile/ProfileSkillEdit';
import ProfileRootProjectDetail from '../../routes/profile/ProfileRootProjectDetail';

import Engineer from '../../routes/engineer/Engineer';
import EngineerDetail from '../../routes/engineer/EngineerDetail';
import EngineerPrevious from '../../routes/engineer/EngineerPrevious';
import EngineerPreviousDetail from '../../routes/engineer/EngineerPreviousDetail';
import EngineerProjectDetail from '../../routes/engineer/EngineerProjectDetail';

import Project from '../../routes/project/Project';
import ProjectCreate from '../../routes/project/ProjectCreate';
import ProjectDetail from '../../routes/project/ProjectDetail';
import ProjectEdit from '../../routes/project/ProjectEdit';
import ProjectEngineer from '../../routes/project/ProjectEngineer';
import ProjectEngineerPrevious from '../../routes/project/ProjectEngineerPrevious';
import ProjectEngineerPreviousDetail from '../../routes/project/ProjectEngineerPreviousDetail';

import Role from '../../routes/role/role';

import Docusign from '../../routes/Docusign/Docusign';

import Nomatch from '../../routes/Nomatch';
import Signin from '../../routes/Signin';
import SignUp from '../../routes/SignUp';
import ForgotPassword from '../../routes/ForgotPassword';

import { signOut, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

import "./Header.css";

function Header() {
  const [state, setState] = useState({
    left: false,
  });
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        if (currentUser) {
          await currentUser.reload();
          if (currentUser.emailVerified) {
            setUser(currentUser);
            const userDocRef = doc(firestore, `users/${currentUser.uid}/profile`, currentUser.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              setRole(userDocSnap.data().role);
            }
          } else {
            setUser(null);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('Sign out successful');
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const itemsList = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        backgroundColor: "white",
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      role="drawer"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <Typography
          sx={{ textAlign: "center", pt: 4, color: "black", fontSize: 20 }}
        >
          Menu
        </Typography>
        <List>
          <Divider />
          <>
            <ListItemButton onClick={handleSignOut} sx={{ color: "black" }}>
              <ListItemIcon sx={{ color: "black" }}>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign Out"} />
            </ListItemButton>
            <ListItemButton component={Link} to="/profile" sx={{ color: "black" }}>
              <ListItemIcon sx={{ color: "black" }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Profile"} />
            </ListItemButton>
            {role === 'master' && (
              <>
                <ListItemButton component={Link} to="/engineer" sx={{ color: "black" }}>
                  <ListItemIcon sx={{ color: "black" }}>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Engineer"} />
                </ListItemButton>
                <ListItemButton component={Link} to="/project" sx={{ color: "black" }}>
                  <ListItemIcon sx={{ color: "black" }}>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Project"} />
                </ListItemButton>
              </>
            )}
            {role === 'root' && (
              <>
                <ListItemButton component={Link} to="/engineer" sx={{ color: "black" }}>
                  <ListItemIcon sx={{ color: "black" }}>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Engineer"} />
                </ListItemButton>
                <ListItemButton component={Link} to="/project" sx={{ color: "black" }}>
                  <ListItemIcon sx={{ color: "black" }}>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Project"} />
                </ListItemButton>
                <ListItemButton component={Link} to="/role" sx={{ color: "black" }}>
                  <ListItemIcon sx={{ color: "black" }}>
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Role"} />
                </ListItemButton>
              </>
            )}
          </>
        </List>
        <Divider />
      </div>
      {user && (
        <Box sx={{ p: 2 }}>
          {role && (
            <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </Typography>
          )}
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {user.email}
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(135deg, #1C8FBF, #1DBF8A, #B563EC)', // ここにグラデーションの色を設定
          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.3)', // 強めの影で光沢感を強調
        }}
      >
        <Toolbar variant="dense" sx={{ backgroundColor: 'transparent' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer("left", true)}
            sx={{
              backgroundColor: 'rgba(0,0,0,0.2)', // アイコンボタンの背景を少し透かせます
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.4)' }, // ホバー時の効果
              padding: '10px',
            }}
          >
            <MenuIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{
              fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'bold',
              letterSpacing: '0.1em',
              textShadow: '1px 1px 2px #004d40',
              marginLeft: '15px',
            }}
          >
            OptiKys
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {itemsList("left")}
      </Drawer>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {user && role === 'user' && (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile_edit" element={<ProfileEdit />} />
            <Route path="/profile_previous" element={<ProfilePrevious />} />
            <Route path="/profile_previous_create" element={<ProfilePreviousCreate />} />
            <Route path="/profile_previous_detail" element={<ProfilePreviousDetail />} />
            <Route path="/profile_previous_edit" element={<ProfilePreviousEdit />} />
            <Route path="/profile_skill_edit" element={<ProfileSkillEdit />} />
            <Route path="/profile_root_project_detail" element={<ProfileRootProjectDetail />} />
          </>
        )}

        {user && role === 'master' && (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile_edit" element={<ProfileEdit />} />
            <Route path="/profile_previous" element={<ProfilePrevious />} />
            <Route path="/profile_previous_create" element={<ProfilePreviousCreate />} />
            <Route path="/profile_previous_detail" element={<ProfilePreviousDetail />} />
            <Route path="/profile_previous_edit" element={<ProfilePreviousEdit />} />
            <Route path="/profile_skill_edit" element={<ProfileSkillEdit />} />
            <Route path="/profile_root_project_detail" element={<ProfileRootProjectDetail />} />

            <Route path="/engineer" element={<Engineer />} />
            <Route path="/engineer_detail" element={<EngineerDetail />} />
            <Route path="/engineer_previous" element={<EngineerPrevious />} />
            <Route path="/engineer_previous_detail" element={<EngineerPreviousDetail />} />
            <Route path="/engineer_project_detail" element={<EngineerProjectDetail />} />

            <Route path="/project" element={<Project />} />
            <Route path="/project_create" element={<ProjectCreate />} />
            <Route path="/project_detail" element={<ProjectDetail />} />
            <Route path="/project_edit" element={<ProjectEdit />} />
            <Route path="/project_engineer" element={<ProjectEngineer />} />
            <Route path="/project_engineer_previous" element={<ProjectEngineerPrevious />} />
            <Route path="/project_engineer_previous_detail" element={<ProjectEngineerPreviousDetail />} />

            <Route path="/docusign" element={<Docusign />} />
          </>
        )}

        {user && role === 'root' && (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile_edit" element={<ProfileEdit />} />
            <Route path="/profile_previous" element={<ProfilePrevious />} />
            <Route path="/profile_previous_create" element={<ProfilePreviousCreate />} />
            <Route path="/profile_previous_detail" element={<ProfilePreviousDetail />} />
            <Route path="/profile_previous_edit" element={<ProfilePreviousEdit />} />
            <Route path="/profile_skill_edit" element={<ProfileSkillEdit />} />
            <Route path="/profile_root_project_detail" element={<ProfileRootProjectDetail />} />

            <Route path="/engineer" element={<Engineer />} />
            <Route path="/engineer_detail" element={<EngineerDetail />} />
            <Route path="/engineer_previous" element={<EngineerPrevious />} />
            <Route path="/engineer_previous_detail" element={<EngineerPreviousDetail />} />
            <Route path="/engineer_project_detail" element={<EngineerProjectDetail />} />

            <Route path="/project" element={<Project />} />
            <Route path="/project_create" element={<ProjectCreate />} />
            <Route path="/project_detail" element={<ProjectDetail />} />
            <Route path="/project_edit" element={<ProjectEdit />} />
            <Route path="/project_engineer" element={<ProjectEngineer />} />
            <Route path="/project_engineer_previous" element={<ProjectEngineerPrevious />} />
            <Route path="/project_engineer_previous_detail" element={<ProjectEngineerPreviousDetail />} />

            <Route path="/role" element={<Role />} />

            <Route path="/docusign" element={<Docusign />} />
          </>
        )}
        
        <Route path="*" element={<Nomatch />} />
      </Routes>
    </Box>
  );
}

export default Header;




// import React, { useEffect, useState } from "react";
// import AppBar from '@mui/material/AppBar';
// import Box from "@mui/material/Box";
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import Divider from '@mui/material/Divider';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import {
//   Home as HomeIcon,
//   Build as BuildIcon,
//   Assignment as AssignmentIcon,
//   Login as LoginIcon,
//   AdminPanelSettings as AdminPanelSettingsIcon,
// } from "@mui/icons-material";
// import { Link, useNavigate } from 'react-router-dom';
// import { signOut, auth } from '../../firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { doc, getDoc } from 'firebase/firestore';
// import { firestore } from '../../firebase';

// function Header() {
//   const [state, setState] = useState({
//     left: false,
//   });
//   const [user, setUser] = useState(null);
//   const [role, setRole] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       try {
//         if (currentUser) {
//           await currentUser.reload();
//           if (currentUser.emailVerified) {
//             setUser(currentUser);
//             const userDocRef = doc(firestore, `users/${currentUser.uid}/profile`, currentUser.uid);
//             const userDocSnap = await getDoc(userDocRef);
//             if (userDocSnap.exists()) {
//               setRole(userDocSnap.data().role);
//             }
//           } else {
//             setUser(null);
//           }
//         } else {
//           setUser(null);
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleSignOut = async () => {
//     try {
//       await signOut(auth);
//       console.log('Sign out successful');
//       navigate('/');
//     } catch (error) {
//       console.error('Error signing out:', error);
//     }
//   };

//   const toggleDrawer = (open) => {
//     setState({ ...state, left: open });
//   };

//   const itemsList = () => (
//     <Box
//       sx={{
//         width: 250,
//         backgroundColor: "white",
//         height: '100%',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-between',
//       }}
//       role="drawer"
//       onMouseLeave={() => toggleDrawer(false)} // マウスが離れたときに閉じる
//     >
//       <div>
//         <Typography
//           sx={{ textAlign: "center", pt: 4, color: "black", fontSize: 20 }}
//         >
//           Menu
//         </Typography>
//         <List>
//           <Divider />
//           <>
//             <ListItemButton onClick={handleSignOut} sx={{ color: "black" }}>
//               <ListItemIcon sx={{ color: "black" }}>
//                 <LoginIcon />
//               </ListItemIcon>
//               <ListItemText primary={"Sign Out"} />
//             </ListItemButton>
//             <ListItemButton component={Link} to="/profile" sx={{ color: "black" }}>
//               <ListItemIcon sx={{ color: "black" }}>
//                 <HomeIcon />
//               </ListItemIcon>
//               <ListItemText primary={"Profile"} />
//             </ListItemButton>
//             {role === 'master' && (
//               <>
//                 <ListItemButton component={Link} to="/engineer" sx={{ color: "black" }}>
//                   <ListItemIcon sx={{ color: "black" }}>
//                     <BuildIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={"Engineer"} />
//                 </ListItemButton>
//                 <ListItemButton component={Link} to="/project" sx={{ color: "black" }}>
//                   <ListItemIcon sx={{ color: "black" }}>
//                     <AssignmentIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={"Project"} />
//                 </ListItemButton>
//               </>
//             )}
//             {role === 'root' && (
//               <>
//                 <ListItemButton component={Link} to="/engineer" sx={{ color: "black" }}>
//                   <ListItemIcon sx={{ color: "black" }}>
//                     <BuildIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={"Engineer"} />
//                 </ListItemButton>
//                 <ListItemButton component={Link} to="/project" sx={{ color: "black" }}>
//                   <ListItemIcon sx={{ color: "black" }}>
//                     <AssignmentIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={"Project"} />
//                 </ListItemButton>
//                 <ListItemButton component={Link} to="/role" sx={{ color: "black" }}>
//                   <ListItemIcon sx={{ color: "black" }}>
//                     <AdminPanelSettingsIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={"Role"} />
//                 </ListItemButton>
//               </>
//             )}
//           </>
//         </List>
//         <Divider />
//       </div>
//       {user && (
//         <Box sx={{ p: 2 }}>
//           {role && (
//             <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
//               {role.charAt(0).toUpperCase() + role.slice(1)}
//             </Typography>
//           )}
//           <Typography variant="body2" sx={{ textAlign: 'center' }}>
//             {user.email}
//           </Typography>
//         </Box>
//       )}
//     </Box>
//   );

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <AppBar
//         position="static"
//         sx={{
//           background: 'linear-gradient(135deg, #1C8FBF, #1DBF8A, #B563EC)', // グラデーションの色を設定
//           boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.3)', // 強めの影で光沢感を強調
//         }}
//       >
//         <Toolbar variant="dense" sx={{ backgroundColor: 'transparent' }}>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             onMouseEnter={() => toggleDrawer(true)} // マウスを乗せたときにドロワーを開く
//             sx={{
//               backgroundColor: 'rgba(0,0,0,0.2)', // アイコンボタンの背景を少し透かせます
//               '&:hover': { backgroundColor: 'rgba(0,0,0,0.4)' }, // ホバー時の効果
//               padding: '10px',
//             }}
//           >
//             <MenuIcon sx={{ fontSize: '1.5rem' }} />
//           </IconButton>
//           <Typography
//             variant="h6"
//             color="inherit"
//             component="div"
//             sx={{
//               fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
//               fontWeight: 'bold',
//               letterSpacing: '0.1em',
//               textShadow: '1px 1px 2px #004d40',
//               marginLeft: '15px',
//             }}
//           >
//             OptiKys
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         anchor="left"
//         open={state.left}
//         onClose={() => toggleDrawer(false)}
//         PaperProps={{
//           sx: { width: state.left ? 250 : 60 }, // アイコンだけの表示かドロワーをフル表示か制御
//         }}
//       >
//         {itemsList()}
//       </Drawer>
//     </Box>
//   );
// }

// export default Header;

// EngineerPrevious.jsx
import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import theme from '../../theme/theme';
import { firestore } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

export default function EngineerPrevious() {
  const navigate = useNavigate();
  const location = useLocation();
  const { profileId } = location.state || {};
  const [projects, setProjects] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'period_start', direction: 'asc' });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (profileId) {
          const previousProjectsRef = collection(firestore, `users/${profileId}/profile/previous`,profileId);
          const snapshot = await getDocs(previousProjectsRef);
          const projectsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProjects(projectsData);
        }
      } catch (error) {
        console.error('Error fetching projects: ', error);
      }
    };

    fetchProjects();
  }, [profileId]);

  const handleInfoClick = (projectId) => {
    navigate('/engineer_previous_detail', { state: { projectId } });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedProjects = [...projects].sort((a, b) => {
      const dateA = new Date(a[key].replace(/-/g, '/') + '-01');
      const dateB = new Date(b[key].replace(/-/g, '/') + '-01');
      if (dateA < dateB) {
        return direction === 'asc' ? -1 : 1;
      }
      if (dateA > dateB) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setProjects(sortedProjects);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
        <TableContainer component={Paper} sx={{ marginTop: 2, maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography variant="h6" component="div">
                    Previous Projects
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Scale</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Tools</TableCell>
                <TableCell>About</TableCell>
                <TableCell onClick={() => handleSort('period_start')} style={{ cursor: 'pointer' }}>
                  Period {sortConfig.key === 'period_start' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.length > 0 ? (
                projects.map((project, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton onClick={() => handleInfoClick(project.id)}>
                        <InfoIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{project.projectname}</TableCell>
                    <TableCell>{project.scale}</TableCell>
                    <TableCell>{project.position_id}</TableCell>
                    <TableCell>{project.tools_id}</TableCell>
                    <TableCell>{project.about}</TableCell>
                    <TableCell>{project.period_start} -- {project.period_end}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="outlined" sx={{ marginTop: 2 }} onClick={() => navigate(-1)}>
          BACK
        </Button>
      </Container>
    </ThemeProvider>
  );
}

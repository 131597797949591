import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#289b8f',
    },
    primaryhover: {
      main: '#E45E3E',
      dark: '#B64B31',
    },
    secondary: {
      main: '#000000',
    },
  },
});

export default theme;

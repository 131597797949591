import React from 'react';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Box } from '@mui/material';


export default function GenderSelect({ gender, handleGenderChange, error, helperText }) {
  return (
    <FormControl component="fieldset" error={error}>
      <FormLabel component="legend">Gender</FormLabel>
      <RadioGroup
        aria-label="gender"
        name="gender"
        value={gender}
        onChange={handleGenderChange}
        row
      >
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
      {error && <Box sx={{ color: '#E45E3E', mt: 1 }}>{helperText}</Box>}
    </FormControl>
  );
}

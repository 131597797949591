import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CssBaseline, Box, Button, Switch, Grid, Select, MenuItem, FormControl, InputLabel, Chip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { collectionGroup, getDoc,getDocs, doc, updateDoc } from 'firebase/firestore';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { firestore } from '../../firebase';
import theme from '../../theme/theme';
import { styled } from '@mui/system';
import NestedModal from '../../components/func/button/NestedModal';

const ChipContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function ProjectEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = location.state || {};
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [ndaDisplay, setNdaDisplay] = useState(false);
  const [pmValue, setPmValue] = useState([]);
  const [frontendValue, setFrontendValue] = useState([]);
  const [backendValue, setBackendValue] = useState([]);
  const [testValue, setTestValue] = useState([]);
  const [otherValue, setOtherValue] = useState([]);
  const [designValue, setDesignValue] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [startPeriod, setStartPeriod] = useState('');
  const [endPeriod, setEndPeriod] = useState('');
  const [startPeriodError, setStartPeriodError] = useState('');
  const [endPeriodError, setEndPeriodError] = useState('');
  const dateRegex = /^(19|20)\d{2}\/(0[1-9]|1[0-2])$/;

  useEffect(() => {
    fetchProfiles();
    fetchProjectData();
  }, []);

  const fetchProfiles = async () => {
    try {
      const profilesCollectionGroup = collectionGroup(firestore, 'profile');
      const profilesSnapshot = await getDocs(profilesCollectionGroup);
      const profilesData = profilesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProfiles(profilesData);
      setFilteredProfiles(profilesData);
      setDropdownOptions(profilesData);
      setLoading(false);
      console.log('Fetched Profiles:', profilesData);
    } catch (error) {
      console.error('Error fetching profiles: ', error);
      setLoading(false);
    }
  };

  const fetchProjectData = async () => {
    if (!projectId) return;
    try {
      const projectRef = doc(firestore, 'projects', projectId);
      const projectSnapshot = await getDoc(projectRef);
      if (projectSnapshot.exists()) {
        const project = projectSnapshot.data();
        setProjectData(project);
        setProjectName(project.projectName);
        setAdditionalInfo(project.additionalInfo);
        setNdaDisplay(project.ndaDisplay);
        setPmValue(project.pmEmails);
        setFrontendValue(project.frontendEmails);
        setBackendValue(project.backendEmails);
        setTestValue(project.testEmails);
        setOtherValue(project.otherEmails);
        setDesignValue(project.designEmails);
        setStartPeriod(project.startPeriod);
        setEndPeriod(project.endPeriod);
      } else {
        console.error('No such project!');
      }
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  const handleChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleNdaToggle = () => {
    setNdaDisplay(!ndaDisplay);
  };

  const handleSearch = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = profiles.filter(profile =>
      (profile.name && profile.name.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.email && profile.email.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.country_id && profile.country_id.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.gender_id && profile.gender_id.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.programmingLanguages && profile.programmingLanguages.some(lang => lang.toLowerCase().includes(lowercasedSearchTerm))) ||
      (profile.tools && profile.tools.some(tool => tool.toLowerCase().includes(lowercasedSearchTerm)))
    );
    setFilteredProfiles(filtered);
    setDropdownOptions(filtered);
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilteredProfiles(profiles);
    setDropdownOptions(profiles);
  };

  const handleDetailsClick = (profileId) => {
    navigate('/project_engineer', { state: { profileId } });
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };

  const handlePmChange = (event) => {
    const selectedEmails = event.target.value.map(name => {
      const selectedProfile = profiles.find(profile => profile.name === name);
      return selectedProfile ? selectedProfile.email : null;
    }).filter(email => email);
    setPmValue(selectedEmails);
  };

  const handleFrontendChange = (event) => {
    const selectedEmails = event.target.value.map(name => {
      const selectedProfile = profiles.find(profile => profile.name === name);
      return selectedProfile ? selectedProfile.email : null;
    }).filter(email => email);
    setFrontendValue(selectedEmails);
  };

  const handleBackendChange = (event) => {
    const selectedEmails = event.target.value.map(name => {
      const selectedProfile = profiles.find(profile => profile.name === name);
      return selectedProfile ? selectedProfile.email : null;
    }).filter(email => email);
    setBackendValue(selectedEmails);
  };

  const handleTestChange = (event) => {
    const selectedEmails = event.target.value.map(name => {
      const selectedProfile = profiles.find(profile => profile.name === name);
      return selectedProfile ? selectedProfile.email : null;
    }).filter(email => email);
    setTestValue(selectedEmails);
  };

  const handleOtherChange = (event) => {
    const selectedEmails = event.target.value.map(name => {
      const selectedProfile = profiles.find(profile => profile.name === name);
      return selectedProfile ? selectedProfile.email : null;
    }).filter(email => email);
    setOtherValue(selectedEmails);
  };

  const handleDesignChange = (event) => {
    const selectedEmails = event.target.value.map(name => {
      const selectedProfile = profiles.find(profile => profile.name === name);
      return selectedProfile ? selectedProfile.email : null;
    }).filter(email => email);
    setDesignValue(selectedEmails);
  };

  const chipDelete = (role, email) => {
    switch (role) {
      case 'pm':
        setPmValue(pmValue.filter(value => value !== email));
        break;
      case 'frontend':
        setFrontendValue(frontendValue.filter(value => value !== email));
        break;
      case 'backend':
        setBackendValue(backendValue.filter(value => value !== email));
        break;
      case 'test':
        setTestValue(testValue.filter(value => value !== email));
        break;
      case 'other':
        setOtherValue(otherValue.filter(value => value !== email));
        break;
      case 'design':
        setDesignValue(designValue.filter(value => value !== email));
        break;
      default:
        break;
    }
  };

  const handleUpdate = async () => {
    if (!dateRegex.test(startPeriod)) {
      setStartPeriodError('Please enter a valid start period in YYYY/MM format.');
      return;
    }
    if (!dateRegex.test(endPeriod)) {
      setEndPeriodError('Please enter a valid end period in YYYY/MM format.');
      return;
    }

    const updatedProjectData = {
      projectName,
      additionalInfo,
      ndaDisplay,
      pmEmails: pmValue,
      frontendEmails: frontendValue,
      backendEmails: backendValue,
      testEmails: testValue,
      otherEmails: otherValue,
      designEmails: designValue,
      startPeriod,
      endPeriod
    };

    try {
      const projectRef = doc(firestore, 'projects', projectId);
      await updateDoc(projectRef, updatedProjectData);
      console.log('Project updated:', updatedProjectData);
      setModalOpen(true);
    } catch (error) {
      console.error('Error updating project: ', error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate('/project');
  };

  const handleDateChange = (e, setter, setError) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 4) {
      value = `${value.slice(0, 4)}/${value.slice(4, 6)}`;
    }
    setter(value);
    setError('');
  };

  const handleDateBlur = (value, setError) => {
    if (!dateRegex.test(value)) {
      setError('Please enter a valid period in YYYY/MM format.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" minHeight="400" maxWidth="lg" sx={{ marginTop: 4 }}>
        <CssBaseline />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 4 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Edit Project
                </Typography>
                <TextField
                  label="Project Name"
                  variant="outlined"
                  fullWidth
                  value={projectName}
                  onChange={(e) => handleChange(e, setProjectName)}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="pm-select-label">PM</InputLabel>
                      <Select
                        labelId="pm-select-label"
                        id="pm-select"
                        multiple
                        value={pmValue.map(email => {
                          const profile = profiles.find(profile => profile.email === email);
                          return profile ? profile.name : '';
                        })}
                        onChange={handlePmChange}
                        label="PM"
                        renderValue={(selected) => (
                          <ChipContainer>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => chipDelete('pm', profiles.find(profile => profile.name === value).email)}
                                onMouseDown={(event) => { event.stopPropagation() }}
                              />
                            ))}
                          </ChipContainer>
                        )}
                      >
                        {dropdownOptions.map((profile, index) => (
                          <MenuItem key={index} value={profile.name}>{profile.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="frontend-select-label">Frontend</InputLabel>
                      <Select
                        labelId="frontend-select-label"
                        id="frontend-select"
                        multiple
                        value={frontendValue.map(email => {
                          const profile = profiles.find(profile => profile.email === email);
                          return profile ? profile.name : '';
                        })}
                        onChange={handleFrontendChange}
                        label="Frontend"
                        renderValue={(selected) => (
                          <ChipContainer>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => chipDelete('frontend', profiles.find(profile => profile.name === value).email)}
                                onMouseDown={(event) => { event.stopPropagation() }}
                              />
                            ))}
                          </ChipContainer>
                        )}
                      >
                        {dropdownOptions.map((profile, index) => (
                          <MenuItem key={index} value={profile.name}>{profile.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="design-select-label">Design</InputLabel>
                      <Select
                        labelId="design-select-label"
                        id="design-select"
                        multiple
                        value={designValue.map(email => {
                          const profile = profiles.find(profile => profile.email === email);
                          return profile ? profile.name : '';
                        })}
                        onChange={handleDesignChange}
                        label="Design"
                        renderValue={(selected) => (
                          <ChipContainer>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => chipDelete('design', profiles.find(profile => profile.name === value).email)}
                                onMouseDown={(event) => { event.stopPropagation() }}
                              />
                            ))}
                          </ChipContainer>
                        )}
                      >
                        {dropdownOptions.map((profile, index) => (
                          <MenuItem key={index} value={profile.name}>{profile.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="backend-select-label">Backend</InputLabel>
                      <Select
                        labelId="backend-select-label"
                        id="backend-select"
                        multiple
                        value={backendValue.map(email => {
                          const profile = profiles.find(profile => profile.email === email);
                          return profile ? profile.name : '';
                        })}
                        onChange={handleBackendChange}
                        label="Backend"
                        renderValue={(selected) => (
                          <ChipContainer>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => chipDelete('backend', profiles.find(profile => profile.name === value).email)}
                                onMouseDown={(event) => { event.stopPropagation() }}
                              />
                            ))}
                          </ChipContainer>
                        )}
                      >
                        {dropdownOptions.map((profile, index) => (
                          <MenuItem key={index} value={profile.name}>{profile.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="test-select-label">Test</InputLabel>
                      <Select
                        labelId="test-select-label"
                        id="test-select"
                        multiple
                        value={testValue.map(email => {
                          const profile = profiles.find(profile => profile.email === email);
                          return profile ? profile.name : '';
                        })}
                        onChange={handleTestChange}
                        label="Test"
                        renderValue={(selected) => (
                          <ChipContainer>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => chipDelete('test', profiles.find(profile => profile.name === value).email)}
                                onMouseDown={(event) => { event.stopPropagation() }}
                              />
                            ))}
                          </ChipContainer>
                        )}
                      >
                        {dropdownOptions.map((profile, index) => (
                          <MenuItem key={index} value={profile.name}>{profile.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="other-select-label">Other</InputLabel>
                      <Select
                        labelId="other-select-label"
                        id="other-select"
                        multiple
                        value={otherValue.map(email => {
                          const profile = profiles.find(profile => profile.email === email);
                          return profile ? profile.name : '';
                        })}
                        onChange={handleOtherChange}
                        label="Other"
                        renderValue={(selected) => (
                          <ChipContainer>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => chipDelete('other', profiles.find(profile => profile.name === value).email)}
                                onMouseDown={(event) => { event.stopPropagation() }}
                              />
                            ))}
                          </ChipContainer>
                        )}
                      >
                        {dropdownOptions.map((profile, index) => (
                          <MenuItem key={index} value={profile.name}>{profile.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <TextField
                  label="Additional Information"
                  variant="outlined"
                  multiline
                  rows={6}
                  fullWidth
                  value={additionalInfo}
                  onChange={(e) => handleChange(e, setAdditionalInfo)}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography sx={{ marginRight: 2 }}>Display NDA:</Typography>
                  <Switch
                    checked={ndaDisplay}
                    onChange={handleNdaToggle}
                    inputProps={{ 'aria-label': 'Toggle NDA display' }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="startPeriod"
                      name="startPeriod"
                      label="Start Period"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={startPeriod}
                      onChange={(e) => handleDateChange(e, setStartPeriod, setStartPeriodError)}
                      onBlur={() => handleDateBlur(startPeriod, setStartPeriodError)}
                      error={Boolean(startPeriodError)}
                      helperText={startPeriodError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="endPeriod"
                      name="endPeriod"
                      label="End Period"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={endPeriod}
                      onChange={(e) => handleDateChange(e, setEndPeriod, setEndPeriodError)}
                      onBlur={() => handleDateBlur(endPeriod, setEndPeriodError)}
                      error={Boolean(endPeriodError)}
                      helperText={endPeriodError}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleUpdate}>
                    Update
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={() => navigate('/project')}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <TextField
                  label="Search Words"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{ marginRight: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  sx={{ marginRight: 2 }}
                  startIcon={<SearchIcon />}
                >
                  SEARCH
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleReset}>
                  RESET
                </Button>
              </Box>
              <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography component="h2" variant="h6">
                          Engineers
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Details</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Age</TableCell>
                      <TableCell>Programming Language</TableCell>
                      <TableCell>Tools</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : filteredProfiles.length > 0 ? (
                      filteredProfiles.slice(0, 6).map((profile) => (
                        <TableRow key={profile.id}>
                          <TableCell>
                            <Button color="primary" onClick={() => handleDetailsClick(profile.id)}>
                              <InfoIcon />
                            </Button>
                          </TableCell>
                          <TableCell>{profile.name}</TableCell>
                          <TableCell>{calculateAge(profile.birthday)}</TableCell>
                          <TableCell>{profile.programmingLanguages ? profile.programmingLanguages.join(', ') : ''}</TableCell>
                          <TableCell>{profile.tools ? profile.tools.join(', ') : ''}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No profiles found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 10 }}>
          <Button variant="outlined" onClick={() => navigate('/project')}>
            BACK
          </Button>
        </Box>
        <NestedModal
          open={modalOpen}
          handleClose={handleModalClose}
          title="Update Confirmation"
          description="Your project has been successfully updated."
        />
      </Container>
    </ThemeProvider>
  );
}

export default ProjectEdit;

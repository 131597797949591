
// import { Routes, Route } from 'react-router-dom';
// import Home from './routes/Home';
// import About from './routes/About';
// import Main from './routes/Main';
// import Contact from './routes/Contact';
// import NoMatch from './routes/Nomatch';

import Header from "./components/pages/Header";
// import Footer from "./components/pages/Footer";
import "./App.css";



function App() {
  return (
    <div className="App">
      <header>
        <Header />
      </header>
      <footer>
        {/* <Footer /> */}
      </footer>
    </div>
  );
}

export default App;

import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import SkillSection from '../../components/func/form/SkillSections';
import CustomButton from '../../components/func/button/CustomButton';
import FormContainer from '../../components/func/form/FormContainer';
import OtherSkills from '../../components/func/form/OtherSkills';
import NestedModal from '../../components/func/button/NestedModal';
import theme from '../../theme/theme';
import { auth, firestore } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import skillsData from '../../data/skills.json';

export default function SkillInformationEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const { skillData } = location.state || { skillData: {} };
  const [otherSkill, setOtherSkill] = React.useState(skillData.free || '');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedSkills, setSelectedSkills] = React.useState({
    programmingLanguages: skillData.language ? skillData.language.split(', ') : [],
    tools: skillData.tools ? skillData.tools.split(', ') : [],
    technologies: skillData.technologies ? skillData.technologies.split(', ') : [],
    languages: skillData.languages ? skillData.languages.split(', ') : ['English'], // デフォルトで一つ選択
  });

  const handleEditClick = async () => {
    const user = auth.currentUser;
    try {
      const skillDocRef = doc(firestore, `users/${user.uid}/profile`, user.uid);
      await updateDoc(skillDocRef, {
        programmingLanguages: selectedSkills.programmingLanguages,
        tools: selectedSkills.tools,
        technologies: selectedSkills.technologies,
        languages: selectedSkills.languages, // 新しい「Languages」フィールド
        others: otherSkill,
      });
      setModalOpen(true);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const handleSkillChange = (category, selectedOptions) => {
    setSelectedSkills((prevSkills) => ({
      ...prevSkills,
      [category]: selectedOptions,
    }));
  };

  const handleCancelClick = () => {
    navigate(-1); // 画面遷移（元の画面に戻る）
  };

  const handleOtherSkillChange = (event) => {
    setOtherSkill(event.target.value);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate(-1); // 画面遷移（元の画面に戻る）
  };

  const { programmingLanguages, tools, technologies } = skillsData;

  
  const languages = ['English', 'Japanese', 'Vietnamese'];

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexGrow: 1,
            overflowX: 'auto',
            gap: 2,
          }}
        >
          <FormContainer>
            <Typography component="h2" variant="subtitle1" gutterBottom>
              Skill Detail
            </Typography>
            <SkillSection
              title="Programming Languages"
              options={programmingLanguages}
              selectedOptions={selectedSkills.programmingLanguages}
              onChange={(selectedOptions) => handleSkillChange('programmingLanguages', selectedOptions)}
            />
            <SkillSection
              title="Tools"
              options={tools}
              selectedOptions={selectedSkills.tools}
              onChange={(selectedOptions) => handleSkillChange('tools', selectedOptions)}
            />
            <SkillSection
              title="Technologies"
              options={technologies}
              selectedOptions={selectedSkills.technologies}
              onChange={(selectedOptions) => handleSkillChange('technologies', selectedOptions)}
            />
            {/* 新しい Language セクションを追加 */}
            <SkillSection
              title="Languages"
              options={languages}
              selectedOptions={selectedSkills.languages}
              onChange={(selectedOptions) => handleSkillChange('languages', selectedOptions)}
            />
            <OtherSkills
              label="What are you good at?"
              value={otherSkill}
              onChange={handleOtherSkillChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CustomButton
                label="Edit"
                color="primary"
                variant="contained"
                onClick={handleEditClick}
              />
              <CustomButton
                label="Cancel"
                color="secondary"
                variant="outlined"
                onClick={handleCancelClick}
                sx={{ ml: 2 }}
              />
            </Box>
          </FormContainer>
        </Box>
        <NestedModal
          open={modalOpen}
          handleClose={handleModalClose}
          title={"Edit Confirmation"}
          description={"You have successfully edited your skills."}
        />
      </Container>
    </ThemeProvider>
  );
}

import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme/theme';
import NestedModal from '../../components/func/button/NestedModal';
import { auth, firestore } from '../../firebase';
import { doc, collection, addDoc } from 'firebase/firestore';

export default function ProfilePreviousCreate() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    scale: '',
    position: '',
    tools: '',
    about: '',
    period: '',
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [periodError, setPeriodError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isValidDate = (dateString) => {
    const datePattern = /^((19|20)\d{2}\.(0[1-9]|1[0-2]))$/;
    if (!datePattern.test(dateString)) {
      return false;
    }
    const parts = dateString.split('.');
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[0], 10);
    const date = new Date(year, month);
    return date && date.getFullYear() === year && date.getMonth() === month;
  };

  const handlePeriodChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length > 12) value = value.slice(0, 12);
    if (value.length >= 6 && value.length <= 12) {
      value = value.slice(0, 4) + '.' + value.slice(4, 6) + (value.length > 6 ? ' -- ' : '') + (value.length > 6 ? value.slice(6, 10) + '.' + value.slice(10, 12) : '');
    }
    setFormData({
      ...formData,
      period: value,
    });
  };

  const validatePeriod = () => {
    const [start, end] = formData.period.split(' -- ');
    if (!isValidDate(start) || !isValidDate(end)) {
      setPeriodError('Please enter valid dates in the format YYYY.MM -- YYYY.MM.');
      return false;
    }
    setPeriodError('');
    return true;
  };

  const handleSubmit = async () => {
    if (validatePeriod()) {
      try {
        const user = auth.currentUser;
        if (user) {
          const previousProjectsRef = collection(firestore, `users/${user.uid}/profile/previous`, user.uid);
          const newDocRef = await addDoc(previousProjectsRef, {
            projectname: formData.name,
            scale: formData.scale,
            position_id: formData.position,
            tools_id: formData.tools,
            about: formData.about,
            period_start: formData.period.split(' -- ')[0],
            period_end: formData.period.split(' -- ')[1]
          });
          console.log('Document written with ID: ', newDocRef.id);
          setModalOpen(true);
        } else {
          console.error('No user is signed in.');
        }
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate('/profile_previous');
  };

  const handleCancel = () => {
    navigate('/profile_previous');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
        <Paper sx={{ padding: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Create Project
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="name (example : web app using react)"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="scale"
              label="Scale (example : about 20 people)"
              name="scale"
              value={formData.scale}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="position"
              label="Position (example : PM)"
              name="position"
              value={formData.position}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="tools"
              label="Tools (example : React)"
              name="tools"
              value={formData.tools}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="about"
              label="About (please explain about this project)"
              name="about"
              multiline
              rows={4}
              value={formData.about}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="period"
              label="Period (example : 2024.03 -- 2024.05)"
              name="period"
              value={formData.period}
              onChange={handlePeriodChange}
              error={!!periodError}
              helperText={periodError}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                CREATE
              </Button>
              <Button variant="outlined" onClick={handleCancel}>
                CANCEL
              </Button>
            </Box>
          </Box>
        </Paper>
        <NestedModal
          open={modalOpen}
          handleClose={handleModalClose}
          title="Create Confirmation"
          description="Your project has been successfully created."
        />
      </Container>
    </ThemeProvider>
  );
}

import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const NoMatch = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate('/');
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#289b8f',
      },
      secondary: {
        main: '#000000',
      },
    },
  });
  

  return (
    <Container 
      maxWidth="sm" 
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}
    >
      <ThemeProvider theme={theme}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <ErrorOutlineIcon style={{ fontSize: 60, color: '#289b8f' }} />
          <Typography variant="h4" component="h1" gutterBottom>
            Something went wrong
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            An unexpected error has occurred. Please try again.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleRetry} 
            style={{ marginTop: 20 }}
          >
            Go to Home
          </Button>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default NoMatch;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Paper, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CountrySelect from '../../components/func/form/CountrySelect';
import theme from '../../theme/theme';

function Docusign() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    country: '',
    address: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (event) => {
    setFormData({
      ...formData,
      country: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" minWidth="md" sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CssBaseline />
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography component="h1" variant="h5">
            DocuSign
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name (English)"
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
            />
            <CountrySelect
              country={formData.country}
              handleCountryChange={handleCountryChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="address"
              label="Home Address"
              name="address"
              autoComplete="address"
              value={formData.address}
              onChange={handleChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                CONTINUE
              </Button>
              <Button type="button" variant="outlined" color="secondary" onClick={handleCancel}>
                CANCEL
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default Docusign;

import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const EditIconButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} size="small" sx={{ position: 'absolute', top: 0, right: 0 }}>
      <EditIcon fontSize="small" />
    </IconButton>
  );
};

export default EditIconButton;

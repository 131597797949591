// src/components/func/form/Droppable.js
import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable({ id, children }) {
  const { setNodeRef } = useDroppable({
    id
  });

  return (
    <div ref={setNodeRef} style={{ minHeight: '25px', border: '1px dashed #ccc', padding: '10px' }}>
      {children}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, CssBaseline, IconButton, InputAdornment } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../../src/firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { doc, setDoc } from 'firebase/firestore';
import theme from '../../src/theme/theme';
import validator from 'validator'; 

export default function SignUp() {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await user.reload();
        if (user.emailVerified) {
          setEmailVerified(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (emailVerified) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            navigate('/profile');
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [emailVerified, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');

    const passwordPattern = /^[a-zA-Z0-9]{6,20}$/;

    setEmailError(!validator.isEmail(email));
    setPasswordError(!passwordPattern.test(password));
    setConfirmPasswordError(password !== confirmPassword);
    setGeneralError('');
    setSuccessMessage('');
    setVerificationSent(false);
    setEmailVerified(false);
    setCountdown(30);

    if (validator.isEmail(email) && passwordPattern.test(password) && password === confirmPassword) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await setDoc(doc(firestore, `users/${user.uid}/profile`, user.uid), {
          email: user.email,
          uid: user.uid,
          role: 'user',
          name: 'Null',
          country_id: 'Null',
          gender_id: 'Null',
          birthday: 'Null',
          programmingLanguages: [],
          tools: [],
          technologies: [],
          others: 'Null',
          project_id: 'Null',
        });

        await sendEmailVerification(user);
        console.log('Sign up successful');
        setSuccessMessage('Sign up successful! Please verify your email before logging in.');
        setVerificationSent(true);

        const intervalId = setInterval(async () => {
          await user.reload();
          if (user.emailVerified) {
            clearInterval(intervalId);
            setEmailVerified(true);
          }
        }, 1000);
      } catch (error) {
        console.error('Error signing up:', error);
        if (error.code === 'auth/email-already-in-use') {
          setGeneralError('This email is already in use.');
        } else {
          setGeneralError('Failed to sign up. Please try again.');
        }
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          {verificationSent ? (
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              {emailVerified ? (
                <>
                  <Typography variant="h6" color="primary">
                    Email verified! Redirecting you to your profile in {countdown} seconds. Please wait...
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" color="primary">
                    A verification email has been sent. Please verify your email.
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Once verified, you will be redirected automatically.
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailError}
                helperText={emailError ? "Please enter a valid email address." : ""}
                FormHelperTextProps={{ sx: { color: 'primaryhover.main' } }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={passwordError}
                helperText={passwordError ? "Password must be 6-20 characters long." : ""}
                FormHelperTextProps={{ sx: { color: 'primaryhover.main' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                autoComplete="current-password"
                error={confirmPasswordError}
                helperText={confirmPasswordError ? "Passwords do not match." : ""}
                FormHelperTextProps={{ sx: { color: 'primaryhover.main' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {generalError && (
                <Typography color="error.main" variant="body2" sx={{ mt: 2 }}>
                  {generalError}
                </Typography>
              )}
              {successMessage && (
                <Typography color="success.main" variant="body2" sx={{ mt: 2 }}>
                  {successMessage}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="primary"
              >
                Sign Up
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

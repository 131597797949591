// src/components/icons/CustomIcon.js

import React from 'react';
import { SvgIcon } from '@mui/material';

const CustomIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
    <path d="M12 8v4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 16h.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </SvgIcon>
);

export default CustomIcon;

import React from 'react';
import { Grid, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TextDisplayComponent from './TextDisplayComponent';
import CustomIcon from '../icon/CustomIcon'; 

const InfoTextDisplayNoIcon = ({ label, value, period, engineer, status, onInfoClick, iconColor }) => (
  <>
    <Grid item xs={1}>
      <IconButton onClick={() => onInfoClick(label)} style={{ color: iconColor }}>
        <InfoIcon />
      </IconButton>
    </Grid>
    <Grid item xs={3}>
      <TextDisplayComponent label={label} value={value} />
    </Grid>
    <Grid item xs={4.5}>
      <TextDisplayComponent label="Engineer" value={engineer} />
    </Grid>
    <Grid item xs={3.5}>
      <TextDisplayComponent label="Period" value={period} />
    </Grid>
  </>
);

export default InfoTextDisplayNoIcon;

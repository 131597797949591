import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import countriesData from '../../../data/countries.json';

export default function CountrySelect({ country, handleCountryChange, error, helperText }) {
  return (
    <Autocomplete
      fullWidth
      options={countriesData.countries}
      value={country}
      onChange={(event, newValue) => handleCountryChange({ target: { value: newValue } })}
      renderInput={(params) => (
        <TextField
          {...params}
          label="country"
          error={error}
          helperText={helperText}
          margin="normal"
          FormHelperTextProps={{ sx: { color: error ? '#E45E3E' : 'inherit' } }}
          InputProps={{
            ...params.InputProps,
            sx: {
              '& fieldset': {
                borderColor: error ? '#E45E3E' : 'default',
              },
              '&:hover fieldset': {
                borderColor: error ? '#E45E3E' : 'default',
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? '#E45E3E' : 'default',
              },
            },
          }}
        />
      )}
    />
  );
}

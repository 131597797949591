import React from 'react';
import { Box, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedChips, theme) {
  return {
    fontWeight: selectedChips.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const SkillSection = ({ title, options, selectedOptions, onChange }) => {
  const theme = useTheme();
  const [selectedChips, setSelectedChips] = React.useState(selectedOptions);
  const [otherValue, setOtherValue] = React.useState('');
  const [showOtherInput, setShowOtherInput] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('other')) {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
    setSelectedChips(typeof value === 'string' ? value.split(',') : value);
    onChange(typeof value === 'string' ? value.split(',') : value);
  };

  const handleOtherChange = (event) => {
    setOtherValue(event.target.value);
  };

  return (
    <Box sx={{ border: '1px solid #289b8f', borderRadius: 2, p: 2, mb: 2 }}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="multiple-chip-label">{title}</InputLabel>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={selectedChips}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={title} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option} style={getStyles(option, selectedChips, theme)}>
              {option}
            </MenuItem>
          ))}
          <MenuItem key="other" value="other" style={getStyles('other', selectedChips, theme)}>
            Other
          </MenuItem>
        </Select>
      </FormControl>
      {showOtherInput && (
        <TextField
          label="Other"
          value={otherValue}
          onChange={handleOtherChange}
          sx={{ mt: 2 }}
        />
      )}
    </Box>
  );
};

export default SkillSection;

import React from 'react';
import { Grid, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TextDisplayComponent from './TextDisplayComponent';
import CustomIcon from '../icon/CustomIcon'; 

const InfoTextDisplay = ({ label, value, period, engineer, ndaDisplay, onInfoClick, iconColor }) => (
  <>
    <Grid item xs={0.7}>
      <IconButton onClick={() => onInfoClick(label)} style={{ color: iconColor }}>
        <InfoIcon />
      </IconButton>
    </Grid>
    <Grid item xs={2.5}>
      {/* <TextDisplayComponent label={label} value={value} /> */}
      <TextDisplayComponent label="Project" value={value} />
    </Grid>
    <Grid item xs={5.8}>
      <TextDisplayComponent label="Engineer" value={engineer} />
    </Grid>
    <Grid item xs={2}>
      <TextDisplayComponent label="Period" value={period} />
    </Grid>
    {ndaDisplay ? (
      <Grid item xs={0.5}>
        <IconButton onClick={() => onInfoClick('docusign')} style={{ color: iconColor }}>
          <CustomIcon />
        </IconButton>
      </Grid>
    ) : (
      <Grid item xs={0.5}>
        {/* 空欄 */}
      </Grid>
    )}
  </>
);

export default InfoTextDisplay;

// EngineerProjectDetail.jsx
import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Paper, Divider, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, collectionGroup, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import theme from '../../theme/theme';

const EngineerProjectDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = location.state || {};
  const [projectData, setProjectData] = useState(null);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    if (projectId) {
      fetchProjectData(projectId);
      fetchProfiles();
    }
  }, [projectId]);

  const fetchProjectData = async (projectId) => {
    try {
      const projectRef = doc(firestore, 'projects', projectId);
      const projectDoc = await getDoc(projectRef);
      if (projectDoc.exists()) {
        const project = projectDoc.data();
        project.status = getStatus(project.startPeriod, project.endPeriod);
        setProjectData(project);
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  const fetchProfiles = async () => {
    try {
      const profilesCollectionGroup = collectionGroup(firestore, 'profile');
      const profilesSnapshot = await getDocs(profilesCollectionGroup);
      const profilesData = profilesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProfiles(profilesData);
    } catch (error) {
      console.error('Error fetching profiles: ', error);
    }
  };

  const getStatus = (startPeriod, endPeriod) => {
    if (!startPeriod || !endPeriod) return 'Unknown';
    const currentDate = new Date();
    const start = new Date(startPeriod.replace(/\./g, '-'));
    const end = new Date(endPeriod.replace(/\./g, '-'));
    if (end < currentDate) {
      return 'Previous';
    } else if (start > currentDate) {
      return 'Future';
    } else {
      return 'Now';
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const getEngineerNames = (emails) => {
    return emails.map(email => {
      const profile = profiles.find(profile => profile.email === email);
      if (profile) {
        const nameParts = profile.name.split(' ');
        return nameParts[nameParts.length - 1]; // Get the last part of the name
      }
      return email;
    });
  };

  if (!projectData) {
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            No Project Data
          </Typography>
          <Button variant="outlined" sx={{ marginTop: 2 }} onClick={handleBackClick}>
            BACK
          </Button>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
        <Paper sx={{ padding: 4 }}>
          <Typography variant="h4" component="h1" sx={{ marginBottom: 2 }} gutterBottom>
            Project
          </Typography>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Status
            </Typography>
            <Typography variant="body1">{projectData.status}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Name
            </Typography>
            <Typography variant="body1">{projectData.projectName}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Engineers
            </Typography>
            <Grid container spacing={2}>
              {['pmEmails', 'frontendEmails', 'backendEmails', 'designEmails', 'testEmails', 'otherEmails'].map((role, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="h6" component="div" gutterBottom>
                    {role.replace('Emails', '')}
                  </Typography>
                  <Typography variant="body1">
                    {projectData[role] ? getEngineerNames(projectData[role]).join(', ') : 'None'}
                  </Typography>
                  <Divider />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Additional Information
            </Typography>
            <Typography variant="body1">{projectData.additionalInfo}</Typography>
          </Box>
          <Divider />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              Period
            </Typography>
            <Typography variant="body1">{`${projectData.startPeriod} -- ${projectData.endPeriod}`}</Typography>
          </Box>
          <Divider sx={{ marginBottom: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
            <Button variant="outlined" onClick={handleBackClick}>
              BACK
            </Button>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default EngineerProjectDetail;
import React from 'react';
import Button from '@mui/material/Button';

const CustomButton = ({ label, color, variant, onClick }) => {
  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      sx={{ mt: 2, mx: 1 }}
    >
      {label}
    </Button>
  );
};

export default CustomButton;

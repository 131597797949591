import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CssBaseline,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Button
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { collectionGroup, getDocs } from 'firebase/firestore';
import InfoIcon from '@mui/icons-material/Info';
import theme from '../../theme/theme';
import { firestore } from '../../firebase';
import Flag from 'react-world-flags';

function Engineer() {
  const [profiles, setProfiles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfilesAndProjects = async () => {
      try {
        const profilesCollectionGroup = collectionGroup(firestore, 'profile');
        const profilesSnapshot = await getDocs(profilesCollectionGroup);
        const profilesData = profilesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        const projectsCollectionGroup = collectionGroup(firestore, 'projects');
        const projectsSnapshot = await getDocs(projectsCollectionGroup);
        const projectsData = projectsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setProfiles(profilesData);
        setProjects(projectsData);
        setFilteredProfiles(profilesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profiles or projects: ', error);
        setLoading(false);
      }
    };

    fetchProfilesAndProjects();
  }, []);

  const getProjectCount = (email, statusType) => {
    const currentDate = new Date();
    return projects.filter(project => {
      const start = new Date(project.startPeriod.replace(/\./g, '-'));
      const end = new Date(project.endPeriod.replace(/\./g, '-'));
      const isAssigned = [
        ...project.pmEmails,
        ...project.frontendEmails,
        ...project.backendEmails,
        ...project.designEmails,
        ...project.testEmails,
        ...project.otherEmails
      ].includes(email);

      if (statusType === 'current') {
        return isAssigned && start <= currentDate && end >= currentDate;
      } else if (statusType === 'future') {
        return isAssigned && start > currentDate;
      } else if (statusType === 'previous') {
        return isAssigned && end < currentDate;
      }
      return false;
    }).length;
  };

  const handleSearch = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = profiles.filter(profile =>
      (profile.name && profile.name.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.email && profile.email.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.country_id && profile.country_id.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.gender_id && profile.gender_id.toLowerCase().includes(lowercasedSearchTerm)) ||
      (profile.programmingLanguages && profile.programmingLanguages.some(lang => lang.toLowerCase().includes(lowercasedSearchTerm))) ||
      (profile.tools && profile.tools.some(tool => tool.toLowerCase().includes(lowercasedSearchTerm)))
    );
    setFilteredProfiles(filtered);
  };

  const handleReset = () => {
    setSearchTerm('');
    setStatus('');
    setFilteredProfiles(profiles);
  };

  const handleDetailsClick = (profileId) => {
    navigate('/engineer_detail', { state: { profileId } });
  };

  const countryNameToCode = {
    Afghanistan: 'AF',
    Albania: 'AL',
    Algeria: 'DZ',
    Andorra: 'AD',
    Angola: 'AO',
    'Antigua and Barbuda': 'AG',
    Argentina: 'AR',
    Armenia: 'AM',
    Australia: 'AU',
    Austria: 'AT',
    Azerbaijan: 'AZ',
    Bahamas: 'BS',
    Bahrain: 'BH',
    Bangladesh: 'BD',
    Barbados: 'BB',
    Belarus: 'BY',
    Belgium: 'BE',
    Belize: 'BZ',
    Benin: 'BJ',
    Bhutan: 'BT',
    Bolivia: 'BO',
    'Bosnia and Herzegovina': 'BA',
    Botswana: 'BW',
    Brazil: 'BR',
    Brunei: 'BN',
    Bulgaria: 'BG',
    'Burkina Faso': 'BF',
    Burundi: 'BI',
    'Cabo Verde': 'CV',
    Cambodia: 'KH',
    Cameroon: 'CM',
    Canada: 'CA',
    'Central African Republic': 'CF',
    Chad: 'TD',
    Chile: 'CL',
    China: 'CN',
    Colombia: 'CO',
    Comoros: 'KM',
    'Congo, Democratic Republic of the': 'CD',
    'Congo, Republic of the': 'CG',
    'Costa Rica': 'CR',
    Croatia: 'HR',
    Cuba: 'CU',
    Cyprus: 'CY',
    'Czech Republic': 'CZ',
    Denmark: 'DK',
    Djibouti: 'DJ',
    Dominica: 'DM',
    'Dominican Republic': 'DO',
    Ecuador: 'EC',
    Egypt: 'EG',
    'El Salvador': 'SV',
    'Equatorial Guinea': 'GQ',
    Eritrea: 'ER',
    Estonia: 'EE',
    Eswatini: 'SZ',
    Ethiopia: 'ET',
    Fiji: 'FJ',
    Finland: 'FI',
    France: 'FR',
    Gabon: 'GA',
    Gambia: 'GM',
    Georgia: 'GE',
    Germany: 'DE',
    Ghana: 'GH',
    Greece: 'GR',
    Grenada: 'GD',
    Guatemala: 'GT',
    Guinea: 'GN',
    'Guinea-Bissau': 'GW',
    Guyana: 'GY',
    Haiti: 'HT',
    Honduras: 'HN',
    Hungary: 'HU',
    Iceland: 'IS',
    India: 'IN',
    Indonesia: 'ID',
    Iran: 'IR',
    Iraq: 'IQ',
    Ireland: 'IE',
    Israel: 'IL',
    Italy: 'IT',
    Jamaica: 'JM',
    Japan: 'JP',
    Jordan: 'JO',
    Kazakhstan: 'KZ',
    Kenya: 'KE',
    Kiribati: 'KI',
    'Korea, North': 'KP',
    'Korea, South': 'KR',
    Kosovo: 'XK',
    Kuwait: 'KW',
    Kyrgyzstan: 'KG',
    Laos: 'LA',
    Latvia: 'LV',
    Lebanon: 'LB',
    Lesotho: 'LS',
    Liberia: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaysia: 'MY',
    Maldives: 'MV',
    Mali: 'ML',
    Malta: 'MT',
    'Marshall Islands': 'MH',
    Mauritania: 'MR',
    Mauritius: 'MU',
    Mexico: 'MX',
    Micronesia: 'FM',
    Moldova: 'MD',
    Monaco: 'MC',
    Mongolia: 'MN',
    Montenegro: 'ME',
    Morocco: 'MA',
    Mozambique: 'MZ',
    Myanmar: 'MM',
    Namibia: 'NA',
    Nauru: 'NR',
    Nepal: 'NP',
    Netherlands: 'NL',
    'New Zealand': 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigeria: 'NG',
    'North Macedonia': 'MK',
    Norway: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palau: 'PW',
    Palestine: 'PS',
    Panama: 'PA',
    'Papua New Guinea': 'PG',
    Paraguay: 'PY',
    Peru: 'PE',
    Philippines: 'PH',
    Poland: 'PL',
    Portugal: 'PT',
    Qatar: 'QA',
    Romania: 'RO',
    Russia: 'RU',
    Rwanda: 'RW',
    'Saint Kitts and Nevis': 'KN',
    'Saint Lucia': 'LC',
    'Saint Vincent and the Grenadines': 'VC',
    Samoa: 'WS',
    'San Marino': 'SM',
    'Sao Tome and Principe': 'ST',
    'Saudi Arabia': 'SA',
    Senegal: 'SN',
    Serbia: 'RS',
    Seychelles: 'SC',
    'Sierra Leone': 'SL',
    Singapore: 'SG',
    Slovakia: 'SK',
    Slovenia: 'SI',
    'Solomon Islands': 'SB',
    Somalia: 'SO',
    'South Africa': 'ZA',
    'South Sudan': 'SS',
    Spain: 'ES',
    'Sri Lanka': 'LK',
    Sudan: 'SD',
    Suriname: 'SR',
    Sweden: 'SE',
    Switzerland: 'CH',
    Syria: 'SY',
    Taiwan: 'TW',
    Tajikistan: 'TJ',
    Tanzania: 'TZ',
    Thailand: 'TH',
    'Timor-Leste': 'TL',
    Togo: 'TG',
    Tonga: 'TO',
    'Trinidad and Tobago': 'TT',
    Tunisia: 'TN',
    Turkey: 'TR',
    Turkmenistan: 'TM',
    Tuvalu: 'TV',
    Uganda: 'UG',
    Ukraine: 'UA',
    'United Arab Emirates': 'AE',
    'United Kingdom': 'GB',
    'United States': 'US',
    Uruguay: 'UY',
    Uzbekistan: 'UZ',
    Vanuatu: 'VU',
    'Vatican City': 'VA',
    Venezuela: 'VE',
    Vietnam: 'VN',
    Yemen: 'YE',
    Zambia: 'ZM',
    Zimbabwe: 'ZW'
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
        <CssBaseline />
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <TextField
              label="Search Words"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ marginRight: 2 }}
            />
            <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: 2 }}>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="future">Future</MenuItem>
                <MenuItem value="past">Past</MenuItem>
                <MenuItem value="current">Current</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSearch} sx={{ marginRight: 2 }}>
              SEARCH
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleReset}>
              RESET
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ maxHeight: 600, overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10}>
                    <Typography component="h2" variant="h6">
                      Engineers
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Details</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mail</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Programming Language</TableCell>
                  <TableCell>Tools</TableCell>
                  <TableCell>done</TableCell>
                  <TableCell>in progress</TableCell>
                  <TableCell>applying</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : filteredProfiles.length > 0 ? (
                  filteredProfiles.map((profile) => (
                    <TableRow key={profile.id}>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleDetailsClick(profile.id)}>
                          <InfoIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{profile.name}</TableCell>
                      <TableCell>{profile.email}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Flag code={countryNameToCode[profile.country_id]} style={{ width: 24, marginRight: 8 }} />
                          {profile.country_id}
                        </Box>
                      </TableCell>
                      <TableCell>{profile.gender_id}</TableCell>
                      <TableCell>{profile.programmingLanguages ? profile.programmingLanguages.join(', ') : ''}</TableCell>
                      <TableCell>{profile.tools ? profile.tools.join(', ') : ''}</TableCell>
                      <TableCell>{getProjectCount(profile.email, 'previous')}</TableCell>
                      <TableCell>{getProjectCount(profile.email, 'current')}</TableCell>
                      <TableCell>{getProjectCount(profile.email, 'future')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No profiles found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default Engineer;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CssBaseline,
  Button
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import theme from '../../theme/theme';
import { collectionGroup, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';

const Project = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'startPeriod', direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [ndaStatus, setNdaStatus] = useState('');

  const currentDate = new Date();

  useEffect(() => {
    fetchProjects();
    fetchProfiles();
  }, []);

  const fetchProjects = async () => {
    try {
      const querySnapshot = await getDocs(collectionGroup(firestore, 'projects'));
      const projectsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProjects(projectsData);
    } catch (error) {
      console.error('Error fetching projects: ', error);
    }
  };

  const fetchProfiles = async () => {
    try {
      const querySnapshot = await getDocs(collectionGroup(firestore, 'profile'));
      const profilesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProfiles(profilesData);
    } catch (error) {
      console.error('Error fetching profiles: ', error);
    }
  };

  const handleCreateClick = () => {
    navigate('/project_create');
  };

  const getStatus = (startPeriod, endPeriod) => {
    if (!startPeriod || !endPeriod) return 'Unknown';
    const start = new Date(startPeriod.replace(/\./g, '-'));
    const end = new Date(endPeriod.replace(/\./g, '-'));
    if (end < currentDate) {
      return 'done';
    } else if (start > currentDate) {
      return 'applying';
    } else {
      return 'in progress';
    }
  };

  const getNdaStatus = (ndaDisplay) => {
    if (ndaDisplay === true) {
      return 'Open';
    } else if (ndaDisplay === false) {
      return 'Close';
    } else {
      return 'Other';
    }
  };

  const handleInfoClick = (project) => {
    const projectStatus = getStatus(project.startPeriod, project.endPeriod);
    navigate('/project_detail', { state: { project: { ...project, status: projectStatus }, projectId: project.id } });
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });

    setProjects((prevProjects) =>
      [...prevProjects].sort((a, b) => {
        const dateA = new Date(a[key]?.replace(/\./g, '-'));
        const dateB = new Date(b[key]?.replace(/\./g, '-'));

        if (dateA < dateB) return direction === 'asc' ? -1 : 1;
        if (dateA > dateB) return direction === 'asc' ? 1 : -1;
        return 0;
      })
    );
  };

  const handleSearch = () => {
    let filteredProjects = projects;

    if (searchTerm || status || ndaStatus) {
      filteredProjects = projects.filter((project) => {
        const isMatchSearchTerm = !searchTerm || project.projectName.toLowerCase().includes(searchTerm.toLowerCase());
        const isMatchStatus = !status || getStatus(project.startPeriod, project.endPeriod).toLowerCase() === status.toLowerCase();
        const isMatchNdaStatus = !ndaStatus || getNdaStatus(project.ndaDisplay).toLowerCase() === ndaStatus.toLowerCase();
        return isMatchSearchTerm && isMatchStatus && isMatchNdaStatus;
      });
    }

    setProjects(filteredProjects);
  };

  const handleReset = () => {
    setSearchTerm('');
    setStatus('');
    setNdaStatus('');
    fetchProjects(); // Reset to all projects
  };

  const getEngineerNames = (emails) => {
    return emails.map(email => {
      const profile = profiles.find(profile => profile.email === email);
      return profile ? profile.name : email;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" sx={{ marginTop: 4 }}>
        <CssBaseline />
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Search Words"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ marginRight: 2 }}
              />
              <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: 2 }}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="applying">applying</MenuItem>
                  <MenuItem value="in progress">in progress</MenuItem>
                  <MenuItem value="done">done</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: 2 }}>
                <InputLabel id="nda-status-label">NDA</InputLabel>
                <Select
                  labelId="nda-status-label"
                  id="nda-status"
                  value={ndaStatus}
                  onChange={(e) => setNdaStatus(e.target.value)}
                  label="NDA"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Close">Close</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleSearch} sx={{ marginRight: 2 }}>
                SEARCH
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleReset}>
                RESET
              </Button>
            </Box>
            <Button variant="contained" color="primary" onClick={handleCreateClick}>
              NEW CREATE
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ marginTop: 2, maxHeight: 550 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="h6" component="div" sx={{ fontSize: '1.4rem' }}>
                      Project
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="h6" component="div" sx={{ fontSize: '1.1rem' }}>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="div" sx={{ fontSize: '1.1rem' }}>
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="div" sx={{ fontSize: '1.1rem' }}>
                      Engineer
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="div" sx={{ fontSize: '1.1rem' }}>
                      NDA
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => handleSort('startPeriod')} style={{ cursor: 'pointer' }}>
                    <Typography variant="h6" component="div" sx={{ fontSize: '1.1rem', cursor: 'pointer' }}>
                      Period {sortConfig.key === 'startPeriod' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No projects found.
                    </TableCell>
                  </TableRow>
                ) : (
                  projects.map((project, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <IconButton onClick={() => handleInfoClick(project)}>
                          <BorderColorIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ minWidth: 100 }}>
                        {getStatus(project.startPeriod, project.endPeriod)}
                      </TableCell>
                      <TableCell>{project.projectName}</TableCell>
                      <TableCell>
                        {`PM: ${project.pmEmails ? getEngineerNames(project.pmEmails).join(', ') : ''}, 
                          Frontend: ${project.frontendEmails ? getEngineerNames(project.frontendEmails).join(', ') : ''}, 
                          Backend: ${project.backendEmails ? getEngineerNames(project.backendEmails).join(', ') : ''}, 
                          Design: ${project.designEmails ? getEngineerNames(project.designEmails).join(', ') : ''}, 
                          Test: ${project.testEmails ? getEngineerNames(project.testEmails).join(', ') : ''}, 
                          Others: ${project.otherEmails ? getEngineerNames(project.otherEmails).join(', ') : ''}`}
                      </TableCell>
                      <TableCell>{getNdaStatus(project.ndaDisplay)}</TableCell>
                      <TableCell>{`${project.startPeriod} -- ${project.endPeriod}`}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default Project;

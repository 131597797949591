import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Box, CssBaseline, Grid, Link, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CountrySelect from '../../components/func/form/CountrySelect';
import GenderSelect from '../../components/func/form/GenderSelect';
import FormContainer from '../../components/func/form/FormContainer';
import CustomButton from '../../components/func/button/CustomButton';
import NestedModal from '../../components/func/button/NestedModal';
import theme from '../../theme/theme';
import { auth, firestore } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import validator from 'validator';

export default function BasicInformation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [profileData, setProfileData] = useState(null);
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [dobError, setDobError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameEnglishError, setNameEnglishError] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      const user = auth.currentUser;
      if (user) {
        const profileDocRef = doc(firestore, `users/${user.uid}/profile`, user.uid);
        const profileDocSnap = await getDoc(profileDocRef);

        if (profileDocSnap.exists()) {
          const data = profileDocSnap.data();
          setProfileData(data);
          setCountry(data.country_id || '');
          setGender(data.gender_id || '');
          setDob(data.birthday || '');
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchProfileData();
  }, []);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryError(false);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setGenderError(false);
  };

  const handleDobChange = (event) => {
    let value = event.target.value.replace(/\D/g, '').slice(0, 8);
    if (value.length >= 4) {
      value = value.slice(0, 4) + '/' + value.slice(4);
    }
    if (value.length >= 7) {
      value = value.slice(0, 7) + '/' + value.slice(7);
    }
    setDob(value);
    setDobError('');
  };

  const validateEmail = (email) => {
    if (!email) {
      return "Just input your email address here.";
    } else if (!validator.isEmail(email)) {
      return "Just enter a valid email address here.";
    } else {
      return '';
    }
  };

  const validateNameEnglish = (name) => {
    if (!name) {
      return "Just input your name here.";
    } else if (!/^[a-zA-Z\s]*$/.test(name)) {
      return "English only available.";
    } else {
      return '';
    }
  };

  const validateDob = (dob) => {
    if (!dob) {
      return "Just input your birthday here.";
    } else if (!/^((19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01]))$/.test(dob)) {
      return "Just enter a valid your birthday here.";
    } else {
      return '';
    }
  };

  const handleEditClick = async () => {
    const email = document.getElementById('email').value;
    const nameEnglish = document.getElementById('nameEnglish').value;
    const emailValidationError = validateEmail(email);
    const nameEnglishValidationError = validateNameEnglish(nameEnglish);
    const dobValidationError = validateDob(dob);

    if (!country) {
      setCountryError(true);
    }
    if (!gender) {
      setGenderError(true);
    }

    setEmailError(emailValidationError);
    setNameEnglishError(nameEnglishValidationError);
    setDobError(dobValidationError);

    if (!emailValidationError && !nameEnglishValidationError && !dobValidationError && country && gender) {
      try {
        const user = auth.currentUser;
        const profileDocRef = doc(firestore, `users/${user.uid}/profile`, user.uid);
        await updateDoc(profileDocRef, {
          email,
          name: nameEnglish,
          country_id: country,
          gender_id: gender,
          birthday: dob,
        });
        setModalOpen(true);
      } catch (error) {
        console.error('Error updating document:', error);
      }
    }
  };

  const handleCancelClick = () => {
    navigate(-1); // 画面遷移（元の画面に戻る）
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate(-1); // 画面遷移（元の画面に戻る）
  };

  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexGrow: 1,
            overflowX: 'auto',
            gap: 2,
          }}
        >
          <FormContainer>
            <Typography component="h2" variant="subtitle1" gutterBottom>
              Profile Detail
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="nameEnglish"
              label="name (english)"
              name="nameEnglish"
              autoComplete="nameEnglish"
              defaultValue={profileData.name}
              error={!!nameEnglishError}
              helperText={nameEnglishError}
              FormHelperTextProps={{ sx: { color: nameEnglishError ? theme.palette.primaryhover.main : 'inherit' } }}
              InputProps={{
                sx: {
                  '& fieldset': {
                    borderColor: nameEnglishError ? theme.palette.primaryhover.main : 'default',
                  },
                  '&:hover fieldset': {
                    borderColor: nameEnglishError ? theme.palette.primaryhover.main : 'default',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: nameEnglishError ? theme.palette.primaryhover.main : 'default',
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="mail"
              name="email"
              autoComplete="email"
              type="email"
              defaultValue={profileData.email}
              error={!!emailError}
              helperText={emailError}
              FormHelperTextProps={{ sx: { color: emailError ? theme.palette.primaryhover.main : 'inherit' } }}
              InputProps={{
                sx: {
                  '& fieldset': {
                    borderColor: emailError ? theme.palette.primaryhover.main : 'default',
                  },
                  '&:hover fieldset': {
                    borderColor: emailError ? theme.palette.primaryhover.main : 'default',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: emailError ? theme.palette.primaryhover.main : 'default',
                  },
                },
              }}
            />
            <CountrySelect
              country={country}
              handleCountryChange={handleCountryChange}
              error={countryError}
              helperText={countryError ? "Just choose your nationality here." : ""}
              FormHelperTextProps={{ sx: { color: countryError ? theme.palette.primaryhover.main : 'inherit' } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="dob"
              label="yyyy/mm/dd"
              name="dob"
              autoComplete="dob"
              placeholder="yyyy/mm/dd"
              value={dob}
              onChange={handleDobChange}
              error={!!dobError}
              helperText={dobError}
              FormHelperTextProps={{ sx: { color: dobError ? theme.palette.primaryhover.main : 'inherit' } }}
              InputProps={{
                sx: {
                  '& fieldset': {
                    borderColor: dobError ? theme.palette.primaryhover.main : 'default',
                  },
                  '&:hover fieldset': {
                    borderColor: dobError ? theme.palette.primaryhover.main : 'default',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: dobError ? theme.palette.primaryhover.main : 'default',
                  },
                },
              }}
            />
            <GenderSelect
              gender={gender}
              handleGenderChange={handleGenderChange}
              error={genderError}
              helperText={genderError ? "Just choose your gender here." : ""}
              FormHelperTextProps={{ sx: { color: genderError ? theme.palette.primaryhover.main : 'inherit' } }}
            />
            <Grid item xs>
              <Link href="/forgot-password" variant="body2" color="primary">
                Forgot password?
              </Link>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CustomButton
                label="Edit"
                color="primary"
                variant="contained"
                onClick={handleEditClick}
              />
              <CustomButton
                label="Cancel"
                color="secondary"
                variant="outlined"
                onClick={handleCancelClick}
              />
            </Box>
          </FormContainer>
        </Box>
        <NestedModal
          open={modalOpen}
          handleClose={handleModalClose}
          title={"Edit Confirmation"}
          description={"You have successfully edited your profile."}
        />
      </Container>
    </ThemeProvider>
  );
}
